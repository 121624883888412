import { NTheme } from 'kudi-component-library';
import { Suspense } from 'react';
import { Box, Flex } from 'rebass';
import { ThemeProvider } from 'styled-components';
import { Alert, Rotate } from '../assets';
import Modal from './Modal';
import { AppBody, AppHeaderText, InfoText } from './styles';
import Loader from './Loader';
import { formatAmount } from '../utils';

export default function FailedResponse({
  error,
  amount,
  currency,
  id,
  customId,
}: {
  error?: string;
  amount: number | undefined;
  currency?: string;
  id: string | undefined;
  customId?: string;
}) {
  const handleTryAgain = () => {
    window.location.href = `/checkout/${customId || id}`;
  };

  const handleClose = () => {
    window.close();
  };

  return (
    <ThemeProvider theme={NTheme}>
      <AppBody>
        <Flex justifyContent={'center'} alignItems={'center'} height={'100vh'}>
          <Suspense fallback={<Loader />}>
            <Modal showModal={true} onClose={handleClose}>
              <Flex
                bg="#FFFFFF"
                px="20px"
                py="60px"
                style={{ borderRadius: '4px' }}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Alert />
                <AppHeaderText mt="20px">Failed transaction</AppHeaderText>
                {error ? (
                  <InfoText textAlign="center" mt="20px">
                    {error}{' '}
                  </InfoText>
                ) : (
                  <>
                    <InfoText textAlign="center" mt="20px">
                      We couldn't confirm your payment of{' '}
                    </InfoText>{' '}
                    <br />
                    <InfoText textAlign="center" color="n-dark" fontWeight={700}>
                      {amount && `${currency} ${formatAmount(amount)}`}
                    </InfoText>
                  </>
                )}
                <Flex
                  onClick={handleTryAgain}
                  className="cursor-pointer"
                  alignItems="center"
                  mt="30px"
                  justifyContent="center"
                >
                  <Box mr="10px">
                    <Rotate />
                  </Box>{' '}
                  <InfoText fontWeight={500}>Try again</InfoText>
                </Flex>
              </Flex>
            </Modal>
          </Suspense>
        </Flex>
      </AppBody>
    </ThemeProvider>
  );
}
