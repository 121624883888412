/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { QRCode } from 'react-qrcode-logo';
import FadeIn from 'react-fade-in/lib/FadeIn';
import {
  TransferDetails,
  MobileControl,
  QrCodeWrapper,
  QrScreenWrapper,
  DownloadAppWrapper,
  MainQrWrapper,
  BusinessName,
  HowItWorks,
  Cashback,
  Earn,
  GiftWrapper,
  CvvTooltip,
  QrIconWrapper,
} from '../style';
import { Flex, Box, Button } from 'rebass';
import { Typography } from 'kudi-component-library';
import { handleCallbackUrl, ScreenTypes } from '../../../utils';
import { Orderdetails } from '../../../redux/slice';
import { ButtonText } from '../../styles';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import { confirmBankTransfer, fetchQrCode } from '../../../redux/action';
import AppStoreIcon from '../../../assets/app-store';
import GooglePlayIcon from '../../../assets/google-play';
import Loader from '../../Loader';
import NombaQrFailed from './FailedQr';
import { GiftIcon, QrIcon } from '../../../assets';

interface QrCode {
  code: string;
  link: string;
}

interface NombaQrProps {
  orderDetails: Orderdetails | null;
  onMoveToNewScreen: (newscreen: ScreenTypes, error: string, saveCard: boolean) => void;
  changePaymentMethod: () => void;
  customId?: string;
  setShowCustomModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NombaQr({ orderDetails, changePaymentMethod, onMoveToNewScreen }: NombaQrProps) {
  const [currentSubScreen, setCurrentSubScreen] = useState<ScreenTypes>('NombaQR');
  const [fetchingQr, setFetchingQr] = useState<boolean>(true);
  const [qrCode, setQrCode] = useState<QrCode | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { confirmResponse } = useSelector((state: RootState) => state.order);

  const cancel = () => {
    if (orderDetails) {
      handleCallbackUrl(
        orderDetails?.data?.order?.callbackUrl,
        orderDetails?.data?.order?.orderId,
        orderDetails?.data?.order?.orderReference
      );
    }
  };

  const handleFetchQrCode = async () => {
    if (!orderDetails?.data?.order?.orderId) return;
    setFetchingQr(true);
    const response = await dispatch(fetchQrCode({ orderId: orderDetails?.data?.order?.orderId }));

    if (response?.payload?.code === '00') {
      setFetchingQr(false);
      setQrCode(response?.payload?.data);
    } else {
      setFetchingQr(false);
      setCurrentSubScreen('NombaQRFailed');
    }
  };

  useEffect(() => {
    handleFetchQrCode();
  }, []);

  useEffect(() => {
    if (!orderDetails?.data?.order?.orderId) return;

    let interval: NodeJS.Timeout;

    // Goal 1: Don't call confirmBankTransfer for the first 3 seconds
    const timeout = setTimeout(() => {
      // Goal 2: Call confirmBankTransfer every 2 seconds for the first 10 seconds
      interval = setInterval(() => {
        dispatch(
          confirmBankTransfer({
            orderReference: orderDetails?.data?.order?.orderId,
            transactionType: 'NOMBA_QR',
          })
        );
      }, 2000);

      // Goal 3: Call confirmBankTransfer every 5 seconds for the next 1 minute
      setTimeout(() => {
        clearInterval(interval);
        interval = setInterval(() => {
          dispatch(
            confirmBankTransfer({
              orderReference: orderDetails?.data?.order?.orderId,
              transactionType: 'NOMBA_QR',
            })
          );
        }, 5000);
      }, 10000); // 10 seconds

      // Goal 4: Call confirmBankTransfer every 15 seconds for the rest of the time
      setTimeout(() => {
        clearInterval(interval);
        interval = setInterval(() => {
          dispatch(
            confirmBankTransfer({
              orderReference: orderDetails?.data?.order?.orderId,
              transactionType: 'NOMBA_QR',
            })
          );
        }, 15000);
      }, 70000); // 1 minute and 10 seconds
    }, 3000); // 3 seconds

    // Clean up interval
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [dispatch, orderDetails]);

  useEffect(() => {
    if (confirmResponse === null) {
      return;
    }
    if (confirmResponse?.data?.status) {
      onMoveToNewScreen('SuccessResponse', '', false);
      return;
    }
  }, [confirmResponse, onMoveToNewScreen]);

  const handleDownloadLink = (platform: string) => {
    if (platform === 'apple') {
      window.open('https://apps.apple.com/ng/app/nomba-mobile-banking/id1625708506');
    } else {
      window.open('https://play.google.com/store/apps/details?id=com.nomba.business&pcampaignid=web_share');
    }
  };

  return (
    <MainQrWrapper>
      <FadeIn delay={100}>
        {fetchingQr ? (
          <Flex justifyContent="center" flexDirection="column" alignItems="center" mt="30%" style={{ height: '100%' }}>
            <Loader />
            <Typography fontSize="12px">Hold on. Generating QR...</Typography>
          </Flex>
        ) : (
          <>
            {currentSubScreen === 'NombaQR' && (
              <>
                <FadeIn delay={100}>
                  <Cashback>
                    <GiftWrapper>
                      <GiftIcon />
                    </GiftWrapper>
                    <Earn>Earn 2% cashback&nbsp;</Earn>on your first three QR payments
                  </Cashback>
                  <TransferDetails style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <Typography fontSize="14px" color="n-dark" fontWeight={500} style={{ textAlign: 'center' }}>
                      Scan QR with your Nomba App to pay
                    </Typography>
                  </TransferDetails>
                  <QrScreenWrapper>
                    <QrCodeWrapper>
                      <BusinessName>{orderDetails?.data?.order?.businessName}</BusinessName>
                      <QRCode
                        style={{
                          borderRadius: '8px',
                        }}
                        logoHeight={50}
                        logoWidth={50}
                        size={150}
                        id="qr-code"
                        value={qrCode?.link || ''}
                      />
                      <Box className="help-container">
                        <HowItWorks>See how it works &gt; </HowItWorks>
                        <CvvTooltip className="help-text">
                          <QrIconWrapper>
                            <QrIcon />
                          </QrIconWrapper>

                          <Typography fontSize="12px" color="n-light">
                            <ul style={{ listStyleType: 'disc', marginLeft: '10px' }}>
                              <li>Open your Nomba App</li>
                              <li>Scan the QR code</li>
                              <li>Confirm and Pay! - Super fast</li>
                            </ul>
                          </Typography>
                        </CvvTooltip>
                      </Box>
                    </QrCodeWrapper>
                  </QrScreenWrapper>

                  <MobileControl>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      mt="40px"
                    >
                      <Button
                        onClick={() => changePaymentMethod()}
                        mb="20px"
                        bg="#FFFFFF"
                        color="#121212"
                        variant="large"
                        width="80%"
                        height="60px"
                        className="cursor-pointer change-btn"
                      >
                        <ButtonText>Change payment method</ButtonText>
                      </Button>
                      <Typography onClick={() => cancel()} className="cursor-pointer" color="n-grey4" fontWeight={600}>
                        Cancel payment
                      </Typography>
                    </Box>
                  </MobileControl>
                </FadeIn>
                <DownloadAppWrapper>
                  <Typography fontSize="12px">
                    Don't have Nomba App? <br />
                    Download here:
                  </Typography>

                  <Flex>
                    <AppStoreIcon cursor="pointer" onClick={() => handleDownloadLink('apple')} />
                    <GooglePlayIcon cursor="pointer" onClick={() => handleDownloadLink('google')} />
                  </Flex>
                </DownloadAppWrapper>
              </>
            )}

            {currentSubScreen === 'NombaQRFailed' && (
              <NombaQrFailed changePaymentMethod={changePaymentMethod} setCurrentSubScreen={setCurrentSubScreen} />
            )}
          </>
        )}
      </FadeIn>
    </MainQrWrapper>
  );
}
