import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Flex } from 'rebass';
import { BankPayment, CardPayment, ChevronRight, CompanyLogo } from '../assets';
import CardInput from './CardInput';
import Modal from './Modal';
import {
  AppHeaderText,
  SpacerLine,
  InfoText,
  AppHeader,
  PaymentMethodBox,
  PaymentBoxHeader,
  ErrorText,
} from './styles';
import { formatAmount, ScreenTypes } from '../utils';
import CardOtp from './CardOtp';
import { getOrderDetails } from '../redux/action';
import { AppDispatch, RootState } from '../store';
import BankTransfer from './BankTransfer';
import SuccessResponse from './SuccessResponse';
import FailedResponse from './FailedResponse';
import Loader from './Loader';
import { nombaCheckout } from '../utils/featureFlags';
import OrderNotFound from './OrderNotFound';

interface IModalProps {
  showModal: boolean;
  customId?: string;
}

export default function CheckoutModal({ showModal, customId }: IModalProps) {
  const [currentScreen, setCurrentScreen] = useState<ScreenTypes>('PaymentMethods');
  const [amount, setAmount] = useState<number | undefined>(0);
  const [currency, setCurrency] = useState('');
  const [errorMsg, setErrMsg] = useState<string>('');
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, orderDetails } = useSelector((state: RootState) => state.order);
  const { id: paramsId } = useParams();

  const id = customId || paramsId;

  useEffect(() => {
    const onFailure = (error: string) => {
      setErrMsg(error);
      setCurrentScreen('OrderNotFound');
    };
    id && dispatch(getOrderDetails({ orderId: id, onFailure }));
  }, [dispatch, id]);

  const onMoveToNewScreen = (newscreen: ScreenTypes, error?: string) => {
    if (error) {
      setErrMsg(error);
    }
    if (orderDetails?.code === '00') {
      setAmount(orderDetails?.data?.order?.amount);
      setCurrency(orderDetails?.data?.order?.currency);
      setCurrentScreen(newscreen);
    } else {
      return;
    }
  };

  useEffect(() => {
    if (orderDetails && orderDetails?.code !== '00') {
      setErrMsg(orderDetails?.description);
      setCurrentScreen('FailedResponse');
    }
  }, [orderDetails]);

  return (
    <>
      <Modal showModal={showModal} onClose={() => window.close()}>
        {isLoading && currentScreen === 'PaymentMethods' ? (
          <Flex flexDirection="column" alignItems="center" py="100px">
            <Loader />
          </Flex>
        ) : (
          <>
            <Flex justifyContent={'space-between'} className="border">
              <CompanyLogo />
              {orderDetails?.code === '00' ? (
                <Flex flexDirection="column" alignItems="flex-end">
                  <AppHeaderText>
                    {' '}
                    {orderDetails?.data?.order?.currency} {formatAmount(orderDetails?.data?.order?.amount)}
                  </AppHeaderText>
                  <InfoText>{orderDetails?.data.order?.customerEmail}</InfoText>
                </Flex>
              ) : (
                !nombaCheckout.enabled && (
                  <Flex flexDirection="column" alignItems="flex-end">
                    <ErrorText>
                      <strong>Payment Is Currently Disabled</strong>
                    </ErrorText>
                  </Flex>
                )
              )}
            </Flex>
            <SpacerLine />
            {currentScreen === 'PaymentMethods' && (
              <>
                <Flex flexDirection="column" alignItems="flex-start">
                  <AppHeader>Select payment method 123456777888</AppHeader>
                  <InfoText>Kindly select a payment method</InfoText>
                </Flex>
                <PaymentMethodBox onClick={() => onMoveToNewScreen('BankTransfer')}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      <BankPayment />
                      <Flex flexDirection="column" ml="20px">
                        <PaymentBoxHeader>Bank transfer</PaymentBoxHeader>
                        <InfoText>Use bank transfer to make payment.</InfoText>
                      </Flex>
                    </Flex>
                    <ChevronRight />
                  </Flex>
                </PaymentMethodBox>
                <PaymentMethodBox onClick={() => onMoveToNewScreen('CardInput')}>
                  <Flex justifyContent="space-between" alignItems="center">
                    <Flex alignItems="center">
                      <CardPayment />
                      <Flex flexDirection="column" ml="20px">
                        <PaymentBoxHeader>Card</PaymentBoxHeader>
                        <InfoText>Make immediate payments using your card</InfoText>
                      </Flex>
                    </Flex>
                    <ChevronRight />
                  </Flex>
                </PaymentMethodBox>
              </>
            )}
            {currentScreen === 'CardInput' && <CardInput amount={amount} onMoveToNewScreen={onMoveToNewScreen} />}
            {currentScreen === 'CardOtp' && (
              <CardOtp message={errorMsg} onMoveToNewScreen={onMoveToNewScreen} reference={id} />
            )}
            {currentScreen === 'BankTransfer' && (
              <BankTransfer
                currency={orderDetails?.data?.order?.currency}
                amount={orderDetails?.data?.order?.amount}
                onMoveToNewScreen={onMoveToNewScreen}
              />
            )}
          </>
        )}
      </Modal>

      {currentScreen === 'OrderNotFound' && <OrderNotFound error={errorMsg} />}
      {currentScreen === 'SuccessResponse' && (
        <SuccessResponse redirectUrl={orderDetails?.data?.order.callbackUrl} amount={amount} currency={currency} />
      )}
      {currentScreen === 'FailedResponse' && (
        <FailedResponse error={errorMsg} customId={customId} id={id} amount={amount} currency={currency} />
      )}
    </>
  );
}
