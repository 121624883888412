import { useEffect } from 'react';
import FadeIn from 'react-fade-in/lib/FadeIn';
import { Typography } from 'kudi-component-library';
import { Button, Flex } from 'rebass';
import { ButtonText } from '../../styles';
import { MobileCardDetails, MobileControl, TransferDetails } from '../style';
import { Alert } from '../../../assets';
import envConfig from '../../../utils/envConfig';

export const KlumpCheckout = ({
  onClick,
  changePaymentMethod,
}: {
  onClick: () => void;
  changePaymentMethod: () => void;
}) => {
  useEffect(() => {
    try {
      const scriptUrl = String(envConfig.klumpBaseUrl);

      if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
        const script = document.createElement('script');
        script.src = scriptUrl;
        script.async = true;

        document.body.appendChild(script);
      }

      return () => {
        const script = document.querySelector(`script[src="${scriptUrl}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      };
    } catch (e) {
      throw e;
    }
  }, []);

  return (
    <FadeIn delay={100}>
      <div id="klump__checkout" />
      <TransferDetails style={{ marginTop: '30px' }}>
        <Alert />
        <Typography color="n-grey4" style={{ marginTop: '30px', textAlign: 'center' }}>
          Kindly choose the bank account you use the most—this will help us assess your eligibility for the loan.
        </Typography>

        <Button
          onClick={onClick}
          textAlign="center"
          mt="20px"
          bg="#FFCC00"
          color="#121212"
          variant="large"
          width="100%"
          height="48px"
          className="cursor-pointer"
        >
          <ButtonText>I understand, continue</ButtonText>
        </Button>
      </TransferDetails>

      <MobileCardDetails>
        <Flex mt="30px" justifyContent="center">
          <Alert />
        </Flex>

        <Typography color="n-grey4" style={{ marginTop: '30px', textAlign: 'center' }}>
          Kindly choose the bank account you use the most—this will help us assess your eligibility for the loan.
        </Typography>

        <Button
          onClick={onClick}
          textAlign="center"
          mt="20px"
          bg="#FFCC00"
          color="#121212"
          variant="large"
          width="100%"
          height="48px"
          className="cursor-pointer"
        >
          <ButtonText>I understand, continue</ButtonText>
        </Button>

        <MobileControl onClick={() => changePaymentMethod()} style={{ marginTop: '20px' }}>
          <Button
            bg="#FFFFFF"
            color="#121212"
            variant="large"
            width="100%"
            height="60px"
            className="cursor-pointer change-btn"
          >
            <ButtonText>Change payment method</ButtonText>
          </Button>
        </MobileControl>
      </MobileCardDetails>
    </FadeIn>
  );
};
