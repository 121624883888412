/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useFormik } from 'formik'; // Importing useFormik from Formik library
import { Button, Checkbox, TextInput, Typography } from 'kudi-component-library';
import { filterEmptyValues, formatAmount } from '../../utils';
import { PaymentLinkResponse } from '../../redux/slice';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import CustomSelect from '../../components/CustomSelect';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { createCheckoutLink } from '../../redux/action';

const CustomTextInput = styled(TextInput)`
  font-size: 14px;
  border-radius: 8px;
  border: solid 1px #f2f2f2;
`;

const RecurringText = styled(Typography)`
  text-align: center;
  max-width: 55%;
  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const BoxItem = styled(Flex)<{ backgroundColor: string; borderColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  transition: all 0.3s ease-in-out;
  border: ${(props) => props.borderColor};
  &:hover {
    border: 1px solid black;
  }
  @media (max-width: 600px) {
    padding: 4px 8px;
  }
`;

enum LINKTYPES {
  COLLECT_PAYMENT = 'COLLECT_PAYMENT',
  ACCEPT_DONATION = 'ACCEPT_DONATION',
  SELL_AN_ITEM = 'SELL_AN_ITEM',
  SELL_EVENT = 'SELL_EVENT',
}

const boxesData = [
  {
    label: '+5%',
    value: 5,
    backgroundColor: '#FC0',
    borderColor: '#FC0',
  },
  {
    label: '+10%',
    value: 10,
    backgroundColor: '#FC0',
    borderColor: '#FC0',
  },
  {
    label: '+15%',
    value: 15,
    backgroundColor: '#FC0',
    borderColor: '#FC0',
  },
  {
    label: '+20%',
    value: 20,
    backgroundColor: '#FC0',
    borderColor: '#FC0',
  },
  {
    label: 'Custom Amount',
    backgroundColor: '#FFFCF2',
    borderColor: '#FFF5CC',
  },
];

function calculateDaysToFutureDate(futureDate: string): number | string {
  const today = dayjs();
  const future = dayjs(futureDate);

  if (future.isBefore(today, 'day')) {
    return 'Ended';
  } else {
    const daysDifference = future.diff(today, 'day');
    return `Ends in ${daysDifference} days`;
  }
}

interface PaymentFormProps {
  paymentLinkResponse: PaymentLinkResponse | null;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomId: React.Dispatch<React.SetStateAction<string>>;
}

const validationSchema = Yup.object().shape({
  customerFirstName: Yup.string().required('First name is required'),
  customerLastName: Yup.string().required('Last name is required'),
  customerEmail: Yup.string().email('Invalid email address').required('Email address is required'),
  amountToCharge: Yup.number().typeError('Amount must be a number').positive('Amount must be positive'),
});

const PaymentForm: React.FC<PaymentFormProps> = ({ paymentLinkResponse, setOpenModal, setCustomId }) => {
  const [selectedBox, setSelectedBox] = useState<number | null>(null);
  const handleBoxClick = (index: number) => {
    formik.setFieldValue('tippingAmount', boxesData[index].value!);
    setSelectedBox(index === selectedBox ? null : index);
  };
  const dispatch = useDispatch<AppDispatch>();
  const [donateMultiple, setDonateMultiple] = useState<boolean>(false);
  const { inProgress } = useSelector((state: RootState) => state.order);
  const [selectedFrequency, setSelectedFrequency] = useState<string>('');
  const [errMsg, setErrMsg] = useState<string>('');

  const FREQUENCY_LIST = [
    { text: 'One time', value: 'ONE_TIME' },
    { text: 'Daily', value: 'DAILY' },
    { text: 'Weekly', value: 'WEEKLY' },
    { text: 'Bi-weekly', value: 'BI_WEEKLY' },
    { text: 'Monthly', value: 'MONTHLY' },
    { text: 'Quarterly', value: 'QUARTERLY' },
    { text: 'Twice a year', value: 'TWICE_A_YEAR' },
    { text: 'Yearly', value: 'YEARLY' },
  ];

  const toggleOpenPay = (url: string) => {
    setOpenModal(true);
    const [, checkoutOrderRef] = url.split('/pay/');
    setCustomId(checkoutOrderRef);
  };

  const formik = useFormik({
    initialValues: {
      customerFirstName: '',
      customerLastName: '',
      customerEmail: '',
      amountToCharge: '',
      tippingAmount: '',
      numberOfPayments: '',
      frequency: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { amountToCharge, tippingAmount, ...rest } = values;
      const tipAmount =
        selectedBox === 4
          ? Number(tippingAmount || 0) * 100
          : (Number(tippingAmount) / 100) * Number(amountToCharge || paymentLinkResponse?.data.amount) * 100;

      const payload = filterEmptyValues({
        ...rest,
        amount: Math.round(Number(amountToCharge || paymentLinkResponse?.data.amount) * 100),
        tippingAmount: Number(tipAmount),
        paymentReference: paymentLinkResponse?.data.paymentReference as string,
        currency: paymentLinkResponse?.data?.currency,
      });
      setErrMsg('');

      try {
        const result = await dispatch(createCheckoutLink(payload));
        if (result?.payload?.data) {
          toggleOpenPay(result?.payload?.data);
        } else {
          setErrMsg(result?.payload?.description || 'An error occured, please try again');
        }
      } catch (error: any) {
        setErrMsg(error?.message);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} id="payment-link-form">
      {paymentLinkResponse?.data?.linkType === LINKTYPES.COLLECT_PAYMENT && (
        <>
          <Flex
            style={{
              gap: '32px',
              textAlign: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            mb="32px"
          >
            <Typography variant="n-text5">{paymentLinkResponse?.data?.title}</Typography>
            {!paymentLinkResponse?.data?.isCustomerSetPriceEnabled && (
              <Typography variant="n-text3">
                Pay {paymentLinkResponse?.data?.currency} {formatAmount(paymentLinkResponse?.data?.amount)}
              </Typography>
            )}
            <Typography variant="n-text2" color="n-grey3" style={{ maxWidth: '80%' }}>
              {paymentLinkResponse?.data?.description}
            </Typography>
          </Flex>
          {paymentLinkResponse?.data?.isTippingEnabled && (
            <>
              <Box
                py="24px"
                mb="16px"
                style={{
                  borderTop: 'solid 1px #F2F2F2',
                  borderBottom: 'solid 1px #F2F2F2',
                }}
              >
                <Typography variant="n-text2">Add a tip</Typography>
                <Flex mt="8px" style={{ gap: '8px' }}>
                  {boxesData.map((box, index) => (
                    <BoxItem
                      key={index}
                      borderColor={selectedBox === index ? '1px solid black' : `solid 1px ${box.borderColor || 'none'}`}
                      backgroundColor={box.backgroundColor}
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      onClick={() => handleBoxClick(index)}
                    >
                      <Typography variant="n-text0">{box.label}</Typography>
                      {box?.value && !paymentLinkResponse?.data?.isCustomerSetPriceEnabled && (
                        <Typography variant="n-text0">
                          {paymentLinkResponse?.data?.currency}{' '}
                          {formatAmount((box?.value / 100) * Number(paymentLinkResponse?.data?.amount))}
                        </Typography>
                      )}
                    </BoxItem>
                  ))}
                </Flex>
                {selectedBox === 4 && (
                  <Box width="100%" mt="16px">
                    <CustomTextInput
                      type="number"
                      name="tippingAmount"
                      placeholder="Enter tipping amount"
                      value={formik.values.tippingAmount}
                      error={formik.touched.tippingAmount ? formik.errors.tippingAmount : ''}
                      onChange={formik.handleChange}
                      width="100%"
                    />
                  </Box>
                )}
              </Box>
            </>
          )}
          {paymentLinkResponse?.data?.frequency !== 'ONE_TIME' && (
            <>
              <Flex
                py="24px"
                mb="16px"
                style={{
                  borderTop: 'solid 1px #F2F2F2',
                  borderBottom: 'solid 1px #F2F2F2',
                }}
                alignItems="center"
                justifyContent="center"
              >
                <RecurringText variant="n-text2" fontWeight={500}>
                  You will be charged {paymentLinkResponse?.data?.frequency} payments of{' '}
                  {paymentLinkResponse?.data?.currency} {formatAmount(paymentLinkResponse?.data.amount)} each
                </RecurringText>
              </Flex>
            </>
          )}
          <Box style={{ gap: '32px', textAlign: 'center' }} mb="32px">
            <Flex style={{ gap: '16px' }}>
              <Box width="100%" mb="16px">
                <CustomTextInput
                  type="text"
                  name="customerFirstName"
                  placeholder="First Name"
                  value={formik.values.customerFirstName}
                  error={formik.touched.customerFirstName ? formik.errors.customerFirstName : ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width="100%"
                />
              </Box>
              <Box width="100%" mb="16px">
                <CustomTextInput
                  type="text"
                  name="customerLastName"
                  placeholder="Last Name"
                  value={formik.values.customerLastName}
                  error={formik.touched.customerLastName ? formik.errors.customerLastName : ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width="100%"
                />
              </Box>
            </Flex>
            <Box mb="16px">
              <CustomTextInput
                type="email"
                name="customerEmail"
                placeholder="Email address"
                value={formik.values.customerEmail}
                error={formik.touched.customerEmail ? formik.errors.customerEmail : ''}
                width="100%"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {(paymentLinkResponse?.data?.isCustomerSetPrice ||
              paymentLinkResponse?.data?.isCustomerSetPriceEnabled) && (
              <Box mb="16px">
                <CustomTextInput
                  type="number"
                  name="amountToCharge"
                  placeholder="Amount to charge"
                  value={formik.values.amountToCharge}
                  error={formik.touched.amountToCharge ? formik.errors.amountToCharge : ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width="100%"
                />
              </Box>
            )}
            <Button variant="medium" width="100%" type="submit" loading={inProgress}>
              {paymentLinkResponse?.data?.recurring ? (
                'Pay Now and Subscribe'
              ) : (
                <>
                  {selectedBox === 4 ? (
                    <>
                      Pay {paymentLinkResponse.data.currency}
                      {formatAmount(
                        Number(formik.values.amountToCharge || paymentLinkResponse?.data?.amount) +
                          Number(formik.values.tippingAmount || 0)
                      )}{' '}
                      now
                    </>
                  ) : (
                    <>
                      Pay {paymentLinkResponse?.data?.currency}
                      {formatAmount(
                        Number(formik.values.amountToCharge || paymentLinkResponse?.data.amount) +
                          (Number(formik.values.tippingAmount || 0) / 100) *
                            Number(formik.values.amountToCharge || paymentLinkResponse?.data.amount)
                      )}{' '}
                      now
                    </>
                  )}
                </>
              )}
            </Button>
            <Typography color="n-red" fontSize="12px" style={{ marginTop: '8px' }}>
              {errMsg}
            </Typography>
          </Box>
        </>
      )}
      {paymentLinkResponse?.data?.linkType === LINKTYPES.ACCEPT_DONATION && (
        <>
          {Number(paymentLinkResponse?.data?.amount) > 0 && (
            <Box
              style={{
                borderRadius: '16px',
                border: 'solid 1px #E6E6E6',
                padding: '20px',
              }}
            >
              <Flex flexDirection="column" style={{ gap: '10px' }}>
                <Typography variant="n-text5">
                  {paymentLinkResponse?.data?.currency} {formatAmount(paymentLinkResponse?.data?.totalSales)}
                </Typography>

                <Box style={{ borderRadius: '50px', backgroundColor: '#F2F2F2' }} height={'5px'} width="100%" my={1}>
                  <Box
                    style={{
                      borderRadius: '50px',
                      backgroundColor: '#FFCC00',
                    }}
                    maxWidth="100%"
                    width={`${Math.ceil(
                      (Number(paymentLinkResponse?.data?.totalSales) / Number(paymentLinkResponse?.data?.amount)) *
                        100 || 0
                    )}%`}
                    height="100%"
                  />
                </Box>

                <Flex justifyContent="space-between">
                  <Typography variant="n-text1">
                    raised {paymentLinkResponse?.data?.currency} {formatAmount(paymentLinkResponse?.data?.totalSales)}{' '}
                    of {paymentLinkResponse?.data?.currency} {formatAmount(paymentLinkResponse?.data?.amount)} goal
                  </Typography>
                  {paymentLinkResponse?.data?.endDate && (
                    <Typography variant="n-text1">
                      {calculateDaysToFutureDate(paymentLinkResponse.data.endDate)}
                    </Typography>
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
          <Flex
            style={{
              gap: '32px',
              textAlign: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            my="32px"
          >
            <Typography variant="n-text5"> {paymentLinkResponse?.data?.title}</Typography>
            <Flex my="16px" px="16px" justifyContent="center" width="100%">
              <CustomTextInput
                type="number"
                name="amountToCharge"
                placeholder="Enter amount to donate"
                value={formik.values.amountToCharge}
                error={formik.touched.amountToCharge ? formik.errors.amountToCharge : ''}
                width="100%"
                onChange={formik.handleChange}
              />
            </Flex>
            {paymentLinkResponse?.data?.description && (
              <Typography variant="n-text2" color="n-grey3" style={{ maxWidth: '80%' }}>
                {paymentLinkResponse?.data?.description}
              </Typography>
            )}
          </Flex>
          <Box style={{ gap: '32px', textAlign: 'center' }} mb="32px">
            <Flex style={{ gap: '16px' }}>
              <Box width="100%" mb="16px">
                <CustomTextInput
                  type="text"
                  name="customerFirstName"
                  placeholder="First Name"
                  value={formik.values.customerFirstName}
                  error={formik.touched.customerFirstName ? formik.errors.customerFirstName : ''}
                  onChange={formik.handleChange}
                  width="100%"
                />
              </Box>
              <Box width="100%" mb="16px">
                <CustomTextInput
                  type="text"
                  name="customerLastName"
                  placeholder="Last Name"
                  value={formik.values.customerLastName}
                  error={formik.touched.customerLastName ? formik.errors.customerLastName : ''}
                  onChange={formik.handleChange}
                  width="100%"
                />
              </Box>
            </Flex>
            <Box mb="16px">
              <CustomTextInput
                type="text"
                name="customerEmail"
                placeholder="Email address"
                value={formik.values.customerEmail}
                error={formik.touched.customerEmail ? formik.errors.customerEmail : ''}
                width="100%"
                onChange={formik.handleChange}
              />
            </Box>
            {paymentLinkResponse?.data?.customerSetPriceEnabled && (
              <Box mb="16px">
                <CustomTextInput
                  type="text"
                  name="amountToCharge"
                  placeholder="Amount to charge"
                  value={formik.values.amountToCharge}
                  error={formik.touched.amountToCharge ? formik.errors.amountToCharge : ''}
                  onChange={formik.handleChange}
                  width="100%"
                />
              </Box>
            )}

            {paymentLinkResponse?.data?.recurring && (
              <Flex style={{ gap: '10px' }} mb="16px">
                <Checkbox
                  name="agree"
                  checked={donateMultiple}
                  onChange={() => setDonateMultiple(!donateMultiple)}
                  width="20px"
                  height="20px"
                  checkMarkColor="n-dark"
                >
                  {null}
                </Checkbox>
                <Typography color="n-grey4" variant="n-text2">
                  Donate multiple times
                </Typography>
              </Flex>
            )}
            {donateMultiple && (
              <>
                <Box mb="16px">
                  <CustomSelect
                    value={formik.values.frequency}
                    name="frequency"
                    selectOptions={FREQUENCY_LIST}
                    placeholder="Frequency"
                    width="100%"
                    onChange={(e) => {
                      setSelectedFrequency(FREQUENCY_LIST.filter((item) => item.value === e.target.value)[0].text);
                      formik.handleChange(e);
                    }}
                  />
                </Box>
                <Box mb="16px">
                  <CustomTextInput
                    type="text"
                    name="numberOfPayments"
                    placeholder="No. of payment"
                    value={formik.values.numberOfPayments}
                    error={formik.touched.numberOfPayments ? formik.errors.numberOfPayments : ''}
                    onChange={formik.handleChange}
                    width="100%"
                  />
                </Box>
              </>
            )}
            <Button variant="medium" width="100%" type="submit" loading={inProgress}>
              Donate{' '}
              {formik.values.amountToCharge &&
                `${paymentLinkResponse?.data?.currency} ${formatAmount(formik?.values?.amountToCharge)}`}{' '}
              {selectedFrequency}
            </Button>
            <Typography color="n-red" fontSize="12px" style={{ marginTop: '8px' }}>
              {errMsg}
            </Typography>
          </Box>
        </>
      )}
    </form>
  );
};

export default PaymentForm;
