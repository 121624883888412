import { Order } from '../../../redux/slice';
import envConfig from '../../../utils/envConfig';
import { KlumpCheckout } from './KlumpCheckout';

const KlumpComponent = ({
  order,
  handleSuccess,
  handleError,
  handleClose,
  changePaymentMethod,
}: {
  order?: Order;
  handleSuccess: () => void;
  handleError: () => void;
  handleClose: () => void;
  changePaymentMethod: () => void;
}) => {
  const payWithKlump = () => {
    const payload = {
      publicKey: envConfig.klumpPublicKey,
      data: {
        amount: Number(order?.amount),
        shipping_fee: Number(order?.fixedCharge),
        currency: order?.currency,
        redirect_url: order?.callbackUrl,
        merchant_reference: order?.businessName,
        meta_data: {
          customer: order?.customerId,
          email: order?.customerEmail,
          orderId: order?.orderId,
          orderReference: order?.orderReference,
        },
        items: [
          {
            name: order?.businessName,
            unit_price: Number(order?.orderAmount),
            quantity: 1,
          },
        ],
      },
      onSuccess: handleSuccess,
      onError: handleError,
      onLoad: () => {},
      onOpen: () => {},
      onClose: handleClose,
    };

    // @ts-ignore
    // eslint-disable-next-line
    new Klump(payload);
  };

  return <KlumpCheckout onClick={payWithKlump} changePaymentMethod={changePaymentMethod} />;
};

export default KlumpComponent;
